






import { defineComponent } from '@vue/composition-api';

// Components
import Dialog from '../../atoms/Dialog.vue';
import FormEntryCode from '../../molecules/FormEntryCode';

export default defineComponent({
  name: 'DialogEntryCode',
  components: {
    Dialog,
    FormEntryCode,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const methods = {
      on: {
        close: () => emit('close'),
      },
    };

    return {
      ...methods,
    };
  },
});
