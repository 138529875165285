



































































import * as clipboard from 'clipboard-polyfill/text';
import {
  defineComponent,
  reactive,
  computed,
  toRefs,
} from '@vue/composition-api';

export default defineComponent({
  name: 'FormEntryCode',
  setup(props, { root, emit }) {
    const state = reactive({
      sharedCode: 'Presione boton pegar',
      isPaste: false,
      password: '',
      ingressDisabled: true,
      isLoading: false,
    });

    const calculated = {
      existPassword: computed(
        () => root.$store.getters['events/existPassword'],
      ),
    };

    const methods = {
      on: {
        close: () => emit('close'),
        ingress: () => {
          state.isLoading = true;
          root.$store.dispatch('events/joinPublicDialog', {
            sharedCode: state.sharedCode,
            password: state.password,
          });
        },
        paste: async () => {
          const text = await clipboard.readText();
          state.sharedCode = text;
          state.isPaste = !!text;
          state.ingressDisabled = false;
        },
      },
    };

    return {
      ...methods,
      ...calculated,
      ...toRefs(state),
    };
  },
});
